/*  eslint-disable */
import React from "react"
import member1 from "../../images/contact/imgMember.svg"
import member2 from "../../images/contact/justin.svg"
import member3 from "../../images/contact/member.svg"
import emailIcon from "../../images/contact/emailIcon.svg"
import PhoneIcon from "../../images/contact/phoneIcon.svg"
import {
  Container,
  Layout,
  Email,
  Wrapper,
  ContainerSup,
  Phone,
  Title,
  SubTitle,
  Address,
  Icon,
  Time,
  Infomation,
  MemberName,
  MemberPosition,
  MemberEmail,
  AvatarImage,
  MemberPhone,
  MemberImg,
  MemberContainer,
} from "./components"
import WidthWrapper from "../widthWrapper"
import Header from "src/componentsV2/sections/Header"

const data = {
  title: "We'd love to hear from you:",
  time: "Our NYC HQ is open Mon-Fri 9am-7pm est",
  address1: "169 Madison Ave STE 2586",
  address2: "New York, NY",
  address3: "USA",
  postCode: "10016 ",
  email: "hello@airmason.com",
  phone: "+1(646)918-8673",
  members: [
    {
      img: member1,
      name: "Davejot Sandhu",
      position: "Head of Product",
      email: "Davejot@airmason.com",
      phone: "",
    },
    {
      img: member2,
      name: "Justin Gianfrancesco",
      position: "VP of Global Accounts",
      email: "Justin@airmason.com",
      phone: "+1 (646) 918-8673 (EXT 105)",
    },
    {
      img: member3,
      name: "Tehsin Bhayani",
      position: "Founder & CEO",
      email: "Tehsin@airmason.com",
      phone: "",
    },
  ],
}

const Contact = () => {
  return (
    <>
      <Header />
      <Layout className="pt-90/16 md:pt-40">
        <WidthWrapper>
          <Container style={{ marginBottom: "3rem" }}>
            <Wrapper>
              <Title className="mb-2">{data.title}</Title>
              <a href={`mailto:${data.email}`}>
                <Email>
                  <Icon src={emailIcon}></Icon>
                  {data.email}
                </Email>
              </a>

              <Infomation>
                <Container>
                  <SubTitle>Our Address</SubTitle>
                  <Address>{data.address1}</Address>
                  <Address>{data.address2}</Address>
                  <Address>{data.postCode}</Address>
                  <Address>{data.address3}</Address>
                </Container>
              </Infomation>
              <div>
                <SubTitle>Contact Sales </SubTitle>
                <Address>
                  Call us at
                  <a href={`tel:${data.phone}`} className="no-underline">
                    <Phone>{data.phone}</Phone>
                  </a>
                  or send us a message.
                </Address>
              </div>
              {/* <ContainerSup>
              {data.members.map(item => (
                <MemberContainer>
                  <MemberImg>
                    <AvatarImage src={item.img} alt=""></AvatarImage>
                  </MemberImg>
                  <MemberName>{item.name}</MemberName>
                  <MemberPosition>{item.position}</MemberPosition>
                  <a href={`mailto:${item.email}`} className="no-underline">
                    <MemberEmail>{item.email}</MemberEmail>
                  </a>
                  <MemberPhone>{item.phone}</MemberPhone>
                </MemberContainer>
              ))}
            </ContainerSup> */}
            </Wrapper>
          </Container>
        </WidthWrapper>
      </Layout>
    </>
  )
}
export default Contact
